import { useEffect, useState } from 'react';

export function useObjectURL(object: Blob | MediaSource | undefined | null) {
  const [objectURL, setObjectURL] = useState<string | null>(null);

  useEffect(() => {
    const url = object ? URL.createObjectURL(object) : null;
    setObjectURL(url);
    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [object]);

  return objectURL;
}
