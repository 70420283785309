import { useObjectURL } from '@/hooks/useObjectURL';
import {
  type AdActivityItemType,
  type AdCategoryItemType,
  type AdType,
} from '@/resource/ad';
import { type LocationItemType } from '@/resource/location';
import { type ProfileItemType } from '@/resource/profile';
import { cx } from '@/util/cx';
import { AdItem } from './AdItem';
import styles from './AdItemPreview.module.scss';

type Props = {
  adId?: number;
  adType: AdType;
  title: string;
  description: string;
  profile: ProfileItemType;
  location: LocationItemType;
  activities: AdActivityItemType[];
  category: AdCategoryItemType | null;
  images: Blob[];
  useGeneratedTitle: boolean;
  loading?: boolean;
  percentage?: number;
};

export function AdItemPreview({
  adId = -1,
  adType,
  title,
  description,
  profile,
  location,
  activities,
  category,
  images,
  useGeneratedTitle = false,
  loading = false,
  percentage = 0,
}: Props) {
  const image = useObjectURL(images[0]);

  return (
    <div className={styles.Item}>
      <AdItem
        preview
        item={{
          id: adId,
          type: adType,
          title,
          description,
          status: 'approved',
          offline_date: null,
          distance: -1,
          score: -1,
          expires: null,
          is_offline: false,
          is_public: true,
          use_generated_title: useGeneratedTitle,
          activities: {
            data: activities,
          },
          categories: {
            data: category ? [category] : [],
          },
          image: image
            ? {
                data: {
                  id: -1,
                  h: 208,
                  s: 35,
                  l: 78,
                  crop: {
                    '16:9': {
                      '286': image,
                      '572': image,
                      '858': image,
                      '1200': image,
                    },
                  },
                  resize: {
                    '1200_1200': image,
                    '1600_1600': image,
                  },
                  width: 320,
                  height: 180,
                },
              }
            : null,
          location: {
            data: location,
          },
          profile: {
            data: profile,
          },
        }}
      />

      <div className={cx(styles.Loader, loading && styles.loading)}>
        <span>{Math.round(percentage)}%</span>
      </div>
    </div>
  );
}
