import { CSSProperties } from 'react';
import styles from './IconAvatar.module.scss';
import { cx } from '@/util/cx';

export function IconAvatar({
  palette,
  icon,
  fluid,
  size = 4,
  onClick,
  selected,
  title,
}: {
  palette?: number;
  icon: number;
  fluid?: 'width' | 'height' | 'container';
  size?: number;
  onClick?: () => void;
  selected?: boolean;
  title?: string;
}) {
  const maskImage = `url(/svg/avatar/avatar_${icon}.svg)`;
  const style = {
    '--size': fluid ? undefined : `${size}rem`,
  } as CSSProperties;

  const className = cx(
    styles.IconAvatar,
    fluid ? styles[`size-fluid-${fluid}`] : styles['size-fixed'],
    palette != null && styles.palette,
    palette != null && styles[`palette-${palette}`],
    selected && styles.selected
  );

  const iconStyle = {
    maskImage,
    WebkitMaskImage: maskImage,
  } as CSSProperties;

  if (onClick) {
    return (
      <button
        title={title}
        onClick={onClick}
        type="button"
        className={className}
        style={style}
      >
        <span style={iconStyle} />
      </button>
    );
  }

  return (
    <span className={className} style={style}>
      <span style={iconStyle} />
    </span>
  );
}
