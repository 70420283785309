import { Link } from '@/components/Link';
import { useModal } from '@/hooks/useModal';
import { useAccount } from '@/resource/account';
import { AdType } from '@/resource/ad';
import { cx } from '@/util/cx';
import { RouteType, routeToPath } from '@/util/routeToPath';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import styles from './AdCtaItem.module.scss';

type Props = {
  type: AdType;
  to: RouteType;
  theme?: 0 | 1 | 2 | 3 | 4 | 5;
};

export function AdCtaItem({ type, to, theme }: Props) {
  const modal = useModal();
  const { profile } = useAccount();
  const ref = useRef<HTMLAnchorElement>(null);
  const router = useRouter();

  function guard() {
    modal.open(
      {
        type: 'login',
        props: {
          title: `Om een ${
            type === 'offer' ? 'aanbod' : 'vraag'
          } te plaatsen moet je ingelogd zijn`,
        },
      },
      () => {
        ref.current?.focus();
      }
    );
  }

  return (
    <div
      className={cx(styles.Item, theme != null && styles[`theme-${theme}`])}
      onClick={() => {
        if (profile) {
          router.push(routeToPath(to));
        } else {
          guard();
        }
      }}
    >
      {type === 'offer' ? (
        <div>
          <picture>
            <img src="/svg/illustration/nh_boodschappentassen.svg" alt="" />
          </picture>
          <h3>Wil je een handje helpen?</h3>
          <Link
            to={to}
            ref={ref}
            onClick={(e) => {
              if (!profile) {
                e.preventDefault();
                guard();
              }
            }}
          >
            Plaats aanbod
          </Link>
        </div>
      ) : (
        <div>
          <picture>
            <img src="/svg/illustration/nh_handen_hartje.svg" alt="" />
          </picture>
          <h3>Kun je een helpende hand gebruiken?</h3>
          <Link
            to={to}
            ref={ref}
            onClick={(e) => {
              if (!profile) {
                e.preventDefault();
                guard();
              }
            }}
          >
            Plaats vraag
          </Link>
        </div>
      )}
    </div>
  );
}
