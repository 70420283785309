import { IconAvatar } from '@/components/IconAvatar';
import { Link } from '@/components/Link';
import { useAccount } from '@/resource/account';
import { AdItemType, getAdStatus, isPublicAd } from '@/resource/ad';
import { cx } from '@/util/cx';
import { RouteType } from '@/util/routeToPath';
import styles from './AdItem.module.scss';

type Props = {
  item: AdItemType;
  to: RouteType;
};

export function AdImage({ item, to }: Props) {
  const account = useAccount();
  const label = item.type === 'offer' ? 'Aanbod' : 'Vraag';
  const profile = item.profile.data;
  const profileImage = profile.image?.data;
  const profileCrop = profileImage?.crop['1:1'];
  const adImage = item.image?.data;
  const isPublic = isPublicAd(item);
  const caption = `${label} van ${profile.first_name}`;

  return (
    <Link
      to={to}
      className={cx(
        styles.ItemImage,
        styles[`palette-${[profile.palette_color]}`]
      )}
      title={caption}
      style={
        adImage
          ? {
              backgroundColor: `hsl(${adImage.h}, ${adImage.s}%, ${adImage.l}%)`,
              backgroundImage: `url(${adImage.crop['16:9'][572]})`,
            }
          : undefined
      }
    >
      {profileCrop && !adImage && (
        <picture
          style={{
            backgroundColor: `hsl(${profileImage.h}, ${profileImage.s}%, ${profileImage.l}%)`,
          }}
        >
          <img src={profileCrop[240]} alt={profile.first_name} />
        </picture>
      )}

      {!profileCrop && !adImage && (
        <IconAvatar
          fluid="container"
          icon={profile.icon_id || 1}
          palette={profile.palette_color}
        />
      )}

      {!isPublic && (
        <strong className={styles.Badge}>
          {getAdStatus(account.profile, item)}
        </strong>
      )}
    </Link>
  );
}
