import { AdItemType } from '@/resource/ad';
import { partition } from './partition';

// Generate title based on activities and put emphasis
// on the activities that where selected in the filters.
export function generateAdTitle(
  item: AdItemType,
  filterCategoryIds: number[] = [],
  max = 2
): string {
  if (item.type === 'request' || !item.use_generated_title) {
    return item.title;
  }

  const [emphasis, other] = partition(item.activities.data, (activity) =>
    filterCategoryIds.includes(activity.ad_category_id)
  );

  const activities = emphasis.length ? emphasis : other;

  return activities
    .slice(0, max)
    .map((activity, i, all) => {
      const grammar = i === 0 ? 'Ik ' : i === all.length - 1 ? ' en ' : ', ';
      return `${grammar}${activity.phrase}`;
    })
    .join('');
}
