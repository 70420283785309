import { FavoriteButton } from '@/components/FavoriteButton';
import { Icon } from '@/components/Icon';
import { Link } from '@/components/Link';
import { Text } from '@/components/Text';
import { useModal } from '@/hooks/useModal';
import { getAccount, useAccount } from '@/resource/account';
import { AdItemType, canAccessAd, isFavoriteAd } from '@/resource/ad';
import { addFavorite, removeFavorite } from '@/resource/favorites';
import { cx } from '@/util/cx';
import { formatExpires } from '@/util/date';
import { generateAdTitle } from '@/util/generateAdTitle';
import { RouteType } from '@/util/routeToPath';
import { useBoolean } from 'usehooks-ts';
import { AdImage } from './AdImage';
import styles from './AdItem.module.scss';

type Props = {
  item: AdItemType;
  filterCategoryIds?: number[];
  theme?: 0 | 1 | 2 | 3 | 4 | 5;
  preview?: boolean;
};

export function AdItemPlaceholder() {
  return <div className={cx(styles.Item, styles.placeholder)}></div>;
}

export function AdItem({ item, filterCategoryIds, theme, preview }: Props) {
  const account = useAccount();
  const modal = useModal();
  const isFavorite = isFavoriteAd(account, item);
  const canAccess = canAccessAd(account.profile, item);
  const isInaccessible = !canAccess;
  const posting = useBoolean(false);

  const { expires } = item;
  const location = item.location.data;
  const profile = item.profile.data;
  const title = generateAdTitle(item, filterCategoryIds);
  const label = item.type === 'offer' ? 'Aanbod' : 'Vraag';
  const caption = `${label} van ${profile.first_name}`;
  const detailRoute: RouteType = { type: 'ad_detail', ad: item };
  const locationRoute: RouteType = {
    type: item.type === 'offer' ? 'market_offers' : 'market_requests',
    filter: {
      distance: 15,
      postal_code: location.postal_code,
      sort: 'adjacent',
    },
  };

  return (
    <div
      className={cx(
        styles.Item,
        preview && styles[`is-preview`],
        theme != null && styles[`theme-${[theme]}`],
        isInaccessible && styles.inaccessible
      )}
    >
      <AdImage item={item} to={detailRoute} />

      <div className={styles.ItemBody}>
        <div>
          {expires?.y === 0 &&
            expires.m === 0 &&
            expires.d === 0 &&
            !item.is_offline &&
            item.status !== 'fulfilled' && (
              <small>Nog {formatExpires(expires)}</small>
            )}

          <p>{caption}</p>

          <h3>
            <Link to={detailRoute}>{title}</Link>
          </h3>
        </div>
        <div>
          <Link to={locationRoute}>
            <Icon icon="location" theme={1} /> <strong>{location.place}</strong>
          </Link>

          <FavoriteButton
            type={item.type}
            active={isFavorite}
            disabled={posting.value}
            onClick={(e, refocus) => {
              if (!account.profile) {
                modal.open(
                  {
                    type: 'login',
                    props: {
                      title: `Om een ${
                        item.type === 'offer' ? 'aanbod' : 'vraag'
                      } op te slaan moet je ingelogd zijn`,
                    },
                  },
                  refocus
                );
              } else if (account.profile.deactivated) {
                modal.open(
                  {
                    type: 'message',
                    props: {
                      title: 'Je account is gedeactiveerd',
                      description: (
                        <Text align="center">
                          <Link to={{ type: 'profile_status' }} underline>
                            Heractiveer je account
                          </Link>{' '}
                          om een {item.type === 'offer' ? 'aanbod' : 'vraag'} op
                          te slaan.
                        </Text>
                      ),
                    },
                  },
                  refocus
                );
              } else {
                posting.setTrue();
                if (isFavorite) {
                  removeFavorite(item)
                    .then(() => getAccount().finally(posting.setFalse))
                    .catch(posting.setFalse);
                } else {
                  addFavorite(item)
                    .then(() => getAccount().finally(posting.setFalse))
                    .catch(posting.setFalse);
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
