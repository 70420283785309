import { useRef } from 'react';
import styles from './FavoriteButton.module.scss';

type Props = {
  active: boolean;
  disabled: boolean;
  type: 'offer' | 'request';
  onClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    refocus: () => void
  ) => void;
};

export function FavoriteButton({ active, disabled, type, onClick }: Props) {
  const ref = useRef<HTMLButtonElement>(null);
  const refocus = () => ref.current?.focus();

  return (
    <button
      ref={ref}
      type="button"
      disabled={disabled}
      onClick={(e) => onClick(e, refocus)}
      className={styles.FavoriteButton}
      aria-pressed={active ? 'true' : 'false'}
    >
      {active
        ? `Verwijder uit opgeslagen ${type === 'offer' ? 'aanbod' : 'vragen'}`
        : `${type === 'offer' ? 'Aanbod' : 'Vraag'} opslaan`}
    </button>
  );
}
