import {
  format,
  formatDuration,
  isToday,
  isYesterday,
  isThisYear,
} from 'date-fns';
import { nlBE } from 'date-fns/locale';
import { ExpiresType } from '@/resource/ad';

function fromSqlUtc(value: string): Date | null {
  const parsed = new Date(value);
  return isNaN(parsed.valueOf()) ? null : parsed;
}

export function formatDatetime(datetime: string | Date, fmt: string): string {
  const date = typeof datetime === 'string' ? fromSqlUtc(datetime) : datetime;

  return date ? format(date, fmt, { locale: nlBE }) : '';
}

export function formatExpires({ d, h, i }: ExpiresType) {
  return formatDuration(
    { days: d, hours: h, minutes: i },
    { locale: nlBE, delimiter: ' en ' }
  );
}

export function formatOfflineDate(datetime: string): string {
  return formatDatetime(datetime, 'd MMMM y');
}

export function formatPast(datetime: string): string {
  const date = fromSqlUtc(datetime);
  if (!date) {
    return '';
  }

  const time = formatDatetime(date, 'kk:mm');

  let day = formatDatetime(date, 'd LLL yyyy');
  if (isToday(date)) {
    day = 'Vandaag';
  } else if (isYesterday(date)) {
    day = 'Gisteren';
  } else if (isThisYear(date)) {
    day = formatDatetime(date, 'd LLL ');
  }

  return `${day} ${time}`;
}
